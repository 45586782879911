<template>
  <div class="waste-page">
    <div
      v-if="$can('create', 'waste')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="createWaste()"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend is-text>
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="waste-table"
      ref="wasteTable"
      class="module-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      selectable
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
      @row-selected="detailItemTable"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', 'waste')"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('system/waste/general', 'Waste')"
        />
      </template>
      <template
        v-if="$can('delete', 'waste')"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="
            setItensToDelete({ itens: selected, api: 'system/waste/general', fetchTable: 'Waste' })
          "
        />
      </template>
      <template #cell(company_name)="row">
        {{ row.item.project && row.item.project.company ?  (row.item.project.company.nome_fantasia || row.item.project.company.full_name) : 'Não informado' }}
      </template>
      <template #cell(project_name)="row">
        {{ row.item.project && row.item.project ? (row.item.project.project_name || row.item.project.full_name) : 'Não informado' }}
      </template>
      <template #cell(license_type)="row">
        {{ row.item.licenseType === null ? '' : row.item.licenseType.acronyms }}
      </template>
      <template #cell(address_project)="row">
        {{ `${row.item.project.address}, ${row.item.project.address_number}` }}
      </template>
      <template #cell(main_manager)="row">
        {{ row.item.project.name_responsible }}
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="waste-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      width="500"
      name="Import"
      :draggable="false"
    />
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BTable,
  BButton,
  BFormCheckbox,
  BSpinner,
  BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    BButton,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
    VSModal,
  },
  mixins: [tableConfig],
  data() {
    return {
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Código',
          key: 'waste_code',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Nome',
          key: 'waste_name',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Empresa',
          key: 'company_name',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Empreendimento',
          key: 'project_name',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Tipo Licença',
          key: 'license_type',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
          formatter: 'teste',
        },
        {
          label: 'Número',
          key: 'license_number',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Endereço do empreendimento',
          key: 'address_project',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Resposável',
          key: 'main_manager',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', items: 'waste/getWaste' }),

  },
  created() {
    this.fetchWaste()
    this.fetchAndActivateOperationsTab(false)
  },
  methods: {
    ...mapActions({ fetchWaste: 'waste/fetchWaste', fetchAndActivateOperationsTab: 'waste/fetchAndActivateOperationsTab' }),
    ...mapMutations({ setItensToDelete: 'app/SET_ITENS_DELETE' }),
    createWaste() {
      this.$router.push('residuos/adicionar/detalhes')
    },
    teste() {
      console.log(this.items)
    },
    openModal() {
      this.showModal('Import')
    },
  },
}
</script>
