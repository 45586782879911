<template>
  <div><Waste /></div>
</template>

<script>
import Waste from '@/modules/waste/Waste.vue'

export default {
  components: {
    Waste,
  },
}
</script>

<style>

</style>
