<template>
  <div id="daily-report">
    <b-row>
      <b-col>
        <VSIcons
          name="LogoVerdeSys"
          color-primary="#1BAD82"
          color-secondary="#076E5F"
          width="300"
          height="100"
        />
      </b-col>
      <b-col class="text-right">
        <h1>Relatório de</h1>
        <h1 class="text-primary">
          acompanhamento diário
        </h1>
        <p>Data do relatório: {{ dateReport }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b>Nome:</b> {{ companyReport }}
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b>Nome do usúario:</b> {{ userReport }}
        <hr>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    VSIcons,
    BRow,
    BCol,
  },
}
</script>

<style>

</style>
